.verify-email {
    max-width: 1080px;
	margin: 30px auto;
	padding: 0 30px;
	display: flex;
	flex-direction: column;
	align-items: center;

	ul {
		li {
			display: block;
			width: 150px;
			padding: 6px;
			text-decoration: none;
		}
	}
}
