.product-tile {
    border: 1px solid #ddd;
    box-shadow: 3px 3px 15px rgba(0,0,0,0.3);
    display: flex;
    color: #111;
    text-decoration: none;
    border-radius: 3px;
    max-width: 910px;
    margin: auto;
    margin-top: 50px;

    .image-wrapper {
        max-width: 42%;
        margin: 0 auto;

        img {
            max-width: 100%;
            display: block;
            // this tells flex to giveit more space so it actually fills up to the container max
            width: 1500px;
        }
    }

    .product-tile-content {
        margin: 15px;
    }
}

@media screen and (max-width: 740px) {
    .product-tile {
        flex-direction: column;

        .image-wrapper {
            max-width: none;
            max-height: 50%;
        }
    }
}
