@import "variables";

$battle-max-width: 1080px;
$battle-entry-transition: max-width 0.3s, max-height 0.3s, padding 0.3s, box-shadow 0.3s;

.battle {
    max-width: $battle-max-width;
    margin: 30px auto;
    padding: 0 30px;

    .md-RichEditor-root {
        padding: 15px 0;
    }
}

.close {
    position: absolute;
    top: 0.2em;
    right: 0.2em;
    padding: 0.3em;
    line-height: 0.6;
    background-color: transparent;
    border: 0;
    font-size: 2em;
    cursor: pointer;
}

.battle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.battle-entry-wrapper {
    max-width: 100%;

    .button-vote {
        margin: 0 15px;
    }
}

.battle-image {
    display: flex;
    justify-content: center;

    img {
        transition: $battle-entry-transition;
        width: 100%;
        max-width: 420px;
        max-height: 420px;
        height: auto;
        width: auto;
    }
}

.or-element {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 33px;
}

.button-new-pair {
    border: none;
    font-size: 24px;
    background: none;
    color: green;
    cursor: pointer;
}

.battle-entry {
    border: 1px solid #ddd;
    margin: 15px;
    border-radius: 3px;
    cursor: pointer;
    max-width: 420px;
    max-height: 600px;
    transition: $battle-entry-transition;
    overflow: hidden;
    position: relative;
    box-shadow: 3px 3px 15px rgba(0,0,0,0.3);

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;

    .description {
        max-height: 1600px;
        max-width: $battle-max-width;
        overflow: auto;
        transition: $battle-entry-transition;
        margin-left: 10px;
        margin-right: 10px;
    }

    &:hover {
        box-shadow: 8px 8px 25px rgba(0,0,0,0.3);
    }

    &.hidden,
    .description.hidden {
        max-width: 0;
        max-height: 0;
        padding: 0;
        border: 0;
        overflow: hidden;
    }

    &.expanded {
        max-width: $battle-max-width;
        max-height: 3200px;
        margin-top: 0;
        box-shadow: 15px 15px 35px rgba(0,0,0,0.3);

        .battle-image img {
            max-width: 600px;
        }
    }

    h2 {
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        color: #111;
    }
}

.author-info {
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    font-style: italic;
    color: #666;

    a {
        margin-right: 6px;

        svg {
            width: 15px;
            height: 15px;
            fill: #666;
        }
    }
}

.gallery {
    margin: auto;
    margin-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    max-width: 940px;


    .gallery-entry {
        display: inline-flex;
        margin: auto;
        width: 180px;
        transition: width 0.3s, margin 0.3s, box-shadow 0.3s;

        img {
            width: 160px;
            margin: 15px;
            border: 1px solid #ddd;
            border-radius: 3px;
            box-shadow: 3px 3px 15px rgba(0,0,0,0.3);
            transition: width 0.3s, margin 0.3s, box-shadow 0.3s;
        }

        &:hover {
            img {
                margin: 0px;
                width: 180px;
                box-shadow: 8px 8px 25px rgba(0,0,0,0.3);
            }
        }
    }
}

$thank-you-size: 300px;

.thank-you {
    width: $thank-you-size;
    height: $thank-you-size;
    font-size: 42px;
    text-align: center;
    position: fixed;
    top: calc(50% - #{$thank-you-size / 2});
    left: calc(50% - #{$thank-you-size / 2});
    color: white;
    text-shadow: -1px -1px 0 $color-secondary, 1px -1px 0 $color-secondary, -1px 1px 0 $color-secondary,
        1px 1px 0 $color-secondary;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@mixin afterBg {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
}

// WUT
.wut {
    animation: 1s fullRotation ease infinite;

    &:before {
        @include afterBg;
        background: #{$color-primary-hover}aa;
        border: 1px solid #{$color-secondary}88;
        animation: wutBefore 0.8s 0.4s ease-out infinite;
        z-index: -1;
    }

    &:after {
        @include afterBg;
        background: #{$color-primary-hover}aa;
        border: 1px solid #{$color-secondary}88;
        animation: wut 0.8s ease-out infinite;
        z-index: -1;
    }
}

@keyframes fullRotation {
    0% {
        transform: rotate(-3deg) scale(1) translate3d(0, 0, 0);
    }
    20% {
        transform: rotate(50deg) translate3d(0, 5px, 0);
    }
    40% {
        transform: rotate(-15deg) scale(1.1) translate3d(0, 8px, -5px);
    }
    60% {
        transform: rotate(5deg) translate3d(5px, -8px, -80px);
    }
    90% {
        transform: rotate(-1deg) translate3d(0, 0, 0);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes wut {
    0% {
        border-radius: 50%;
        transform: skew(0) translate3d(0, 0px, 0px);
    }
    10% {
        transform: translate3d(0, -10px, 0);
    }
    50% {
        border-radius: 50%;
    }
    80% {
        border-radius: 30%;
        transform: translate3d(20, 10px, 20px);
    }
    100% {
        border-radius: 50%;
        transform: skew(0) translate3d(0, 0px, 0px);
    }
}

@keyframes wutBefore {
    0% {
        border-radius: 50%;
        transform: translate3d(0, 0px, 0px);
    }
    10% {
        transform: translate3d(-10px, 10px, 0);
    }
    70% {
        border-radius: 50%;
    }
    80% {
        border-radius: 30%;
        transform: translate3d(-20px, -10px, -20px);
    }
    100% {
        border-radius: 50%;
        transform: translate3d(0, 0px, 0px);
    }
}


/* MEDIA QUERIES */
@media screen and (max-width: 945px) {
    .battle-image img {
        max-width: 321px;
    }
}

@media screen and (max-width: 740px) {
    .battle-image img {
        max-width: 420px;
    }
    .battle-wrapper {
        flex-direction: column;
    }
    .or-element {
        flex-direction: row;
    }
}
