@import 'variables';

$preview-width: 600px;
$preview-height: 600px;
$preview-image-padding: 10px;

.competition-entry {
	max-width: 1080px;
	margin: 30px auto;
	padding: 0 30px;

	.md-RichEditor-root {
		padding: 15px 0;
	}
}

.featured-image {
	display: flex;
	align-items: center;
	max-width: $preview-width;
	max-height: $preview-height;
	min-height: 320px;
	min-width: 320px;
	margin: auto;
	text-align: center;
	border: 1px solid $color-primary;
	cursor: pointer;

	&:hover, &:focus, &.drag-over {
		background-color: $color-primary-hover;
	}

	div {
		text-align: center;
		width: 100%;
	}
}

.button-submit {
    display: block;
    width: calc(100% - 30px);
    background-color: #333;
    border: none;
    padding: 15px;
    border-radius: 3px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    text-align: center;
    margin-top: 10px;

    &:hover {
        background-color: #111;
	}
	
	&:disabled {
		background: lightgrey;
		cursor: not-allowed;
	}
}

.featured-image-preview {
	margin: auto;
	padding: $preview-image-padding;
	max-width: calc(#{$preview-width} - 2 * #{$preview-image-padding});
	max-height: calc(#{$preview-height} - 2 * #{$preview-image-padding});
}

.entry-title {
	border: none;
	font-size: 24pt;
	width: 100%;
	background: inherit;
}

