.loading {
    display: inline-block;
    width: 150px;
    height: 17px;
    overflow: hidden;
    position: relative;

    .loading-items {
        width: max-content;
        display: inline-block;
        animation: 15s linear infinite alternate slide;
        position: absolute;
    }

    .loading-overlay {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, lightgrey, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), lightgrey);
    }
}

@keyframes slide {
    from {
        left: 0;
    }

    to {
        left: -1530px;
    }
}
