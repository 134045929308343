$orange5: #fe5b03;
$orange7: #f4d1be;

dialog {
	padding: 0;
	border: 0;
	border-radius: 0.6rem;
	box-shadow: 0 0 1em black;
	color: #222;
}
/* native backdrop */
dialog::backdrop {
	background-color: rgba(0, 0, 0, 0.4);
}
/* polyfill backdrop */
dialog + .backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
}
dialog[open] {
	animation: slide-up 0.4s ease-out;
}

/* The following styles are for older browsers when using
	 the polyfill. These aren’t necessary for Chrome/Firefox. */
dialog {
	display: none;
	position: absolute;
	margin: 0 auto; /* should center it, but not working in Safari */
	max-width: 80vw;
	background-color: white;
}
dialog[open] {
	display: block;
}


/* prettying things up a bit */
.close {
	position: absolute;
	top: 0.2em;
	right: 0.2em;
	padding: 0.3em;
	line-height: 0.6;
	background-color: transparent;
	border: 0;
	font-size: 2em;
}
.modal-header,
.modal-body,
.modal-footer {
	padding: 1em;
	text-align: left;
}

.modal-header {
	margin: 0;
	padding-bottom: 0.6em;
	background-color: $orange7;
	border-top-left-radius: 0.6rem;
	border-top-right-radius: 0.6rem;
	border-bottom: 1px solid $orange5;
	color: #111;
	text-shadow: 0px 0px 2px white;
}

.modal-footer {
	border-top: 1px solid $orange5;
}

input {
	font-size: 15px;

	&:not(:last-child) {
		margin-bottom: 9px;
	}
}

label {
	font-size: 15px;
}

@keyframes slide-up {
	0% {
		opacity: 0;
		transform: translate(0, 15px);
	}
	
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}
