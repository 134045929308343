@import 'variables';


.header-bar {
  *zoom: 1;
  font-family: "Source Sans Pro",sans-serif;
  font-size: 0.82353em;
  font-weight: 700;
  font-style: normal;
  background-color: $color-primary;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
}

.site-nav {
  list-style: none;
  font-size: 0.94118em;
  font-family: "Source Sans Pro",sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: default;
  margin: 0 auto;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
  }

  & > li:first-child > a {
    padding-left: 0;
  }

  .site-nav__link {
    display: block;
    text-decoration: none;
    white-space: nowrap;
    color: #000;
    background-color: #fff;
    font-weight: 700;
    padding: 7px 20px 7px 15px;

    &:hover, &:active, &:focus {
      color: $color-secondary;
    }
  }
}

.site-header__logo {
  text-align: center;
  margin: 0 auto;
  max-width: 100%;
}

.logo__image-wrapper {
  margin: 0 auto;
  max-width: 150px;
  display: block;
  position: relative;
}

.button-link {
  border: none;
  background: none;
  font-style: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;

  &:hover {
    color: $color-secondary;
  }
}

@media screen and (min-width: 769px) {
  .header-bar {
    text-align: right;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
